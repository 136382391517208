<template>
  <div class="o-layout__item">
    <div class="o-layout c-planning">
      <div class="o-layout__item c-planning__item-main">

        <div class="o-layout c-planning__head">
          <div class="o-layout__item o-flex o-flex--align-center o-flex--justify-space-between u-padding-right-none">
            <h3 class="c-title c-title--page-section">
              {{ $t("sessions") }}
            </h3>
            <div class="o-flex">
              <button class="c-button c-button--ghost" @click="collapseAll()">
                collapse all
              </button>
              <button class="c-button c-button--ghost" @click="expandAll()">
                expand all
              </button>

            </div>
          </div>
        </div>

        <div class="o-layout c-planning__session-day" v-for="(sessionDate, i) in sessionDates" v-bind:key="i">
          <div class="o-layout__item c-planning__session-day-head">
            <h4 class="c-planning__session-day-title">
              {{ getDateStr(sessionDate.date) }}
            </h4>

            <button class="c-button c-button--icon c-button--primary"
                    v-on:click.prevent="onAddSessionOnDate(sessionDate.date, i)">
              <svg role="img" class="c-button__icon" style="fill: white;">
                <use xlink:href="images/svg-symbols.svg#plus"></use>
              </svg>
              <span class="c-button__label">{{ $t("session.add") }}</span>
            </button>
          </div>

          <div class="c-planning__session-day-body">

            <div class="o-layout c-planning__session" v-for="session in sessionDate.sessions"
                 v-bind:key="session.id">
              <collapsible open :id="`session-${ session.id }`" no-label ref="sessionWrapper">
                <template v-slot:header>
                  <div class="o-layout__item">
                    <div class="o-layout__item c-planning__session-head">

                      <div class="o-flex o-flex--wrap o-flex--align-center">
                        <div class="o-flex--self-start o-flex--grow">
                          <h5 class="c-planning__session-head-title">
                            {{ sessionTitle(session) }}
                          </h5>
                        </div>
                        <div class="c-planning__session-head-actions">
                          <sceRouteButton label="edit" class="c-button--primary c-button--small"
                            :route="{ name: 'admin.event.discipline.sessions.view', params: { sessionId: session.id } }"/>
                          <sceRouteButton label="order" class="c-button--primary c-button--small"
                            :route="! session.mixed ? { name: 'admin.event.discipline.sessions.order.set', params: { sessionId: session.id, set: 1 } } : { name: 'admin.event.discipline.sessions.order.mixed', params: { sessionId: session.id } }" />
                          <sceRouteButton label="panels" class="c-button--primary c-button--small"
                            :route="{ name: 'admin.event.discipline.sessions.panels', params: { sessionId: session.id } }" />
                          <sceButton class="c-button--ghost c-button--small" icon="svg-symbols.svg#close"
                                     v-on:click="onRemoveSession(session)" />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>

                <template v-slot:content>
                  <div class="o-layout__item">
                    <div class="c-planning__session-head-meta o-layout">
                      <div class="o-layout__item u-3-of-8">
                        {{ session.mixed ? $t("session.setsPanels") : $t("session.sets") }}:
                      </div>
                      <div class="o-layout__item u-2-of-8">
                        {{ session.sets }}
                      </div>
                      <div class="o-layout__item u-3-of-8">
                        <button
                          class="c-button c-button--primary c-button--tiny"
                          v-on:click.prevent="onUpdateSessionCounterProp(session.id, 'sets', true)">
                          <svg role="img" class="c-button__icon" style="fill: #fff;">
                            <use xlink:href="images/svg-symbols.svg#plus-icon"></use>
                          </svg>
                        </button>
                        <button
                          class="c-button c-button--secondary c-button--tiny"
                          v-on:click.prevent="onUpdateSessionCounterProp(session.id, 'sets', false)">
                          <svg role="img" class="c-button__icon" style="fill: #fff;">
                            <use xlink:href="images/svg-symbols.svg#minus-icon"></use>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <div class="c-planning__session-head-meta" v-if="session.mixed">
                      <div class="o-layout" v-for="panel in getExercisePanels(session)" :key="panel.id">
                        <div class="o-layout__item u-push-1-of-8 u-2-of-8">
                          {{ $t("panel") }} {{ panel.id }}:
                        </div>
                        <div class="o-layout__item u-push-1-of-8 u-2-of-8">
                          {{ panel.participations }}
                        </div>
                        <div class="o-layout__item u-push-1-of-8 u-2-of-8 o-flex--justify-start">
                          {{ $t("exercises") }}
                        </div>
                      </div>
                      <div class="o-layout u-margin-vertical-tiny">

                        <div class="o-layout__item u-push-1-of-8 u-2-of-8">
                          Totaal:
                        </div>
                        <div class="o-layout__item u-push-1-of-8 u-2-of-8">
                          {{ getTotalExercisePanels(session) }}
                        </div>
                        <div class="o-layout__item u-push-1-of-8 u-2-of-8 o-flex--justify-start">
                          {{ $t("exercises") }}
                        </div>
                      </div>
                    </div>
                    <div class="c-planning__session-head-meta o-layout">
                      <div class="o-layout__item u-3-of-8">
                        Participations:
                      </div>
                      <div class="o-layout__item u-2-of-8">
                        {{ session.totalParticipations }}
                      </div>
                    </div>
                    <div class="c-planning__session-head-meta o-layout" v-if="session.mixed">
                      <div class="o-layout__item u-3-of-8">
                        Rotations:
                      </div>
                      <div class="o-layout__item u-2-of-8">
                        {{ session.rotations }}
                      </div>
                      <div class="o-layout__item u-3-of-8">
                        <button
                          class="c-button c-button--primary c-button--tiny"
                          v-on:click.prevent="onUpdateSessionCounterProp(session.id, 'rotations', true)">
                          <svg role="img" class="c-button__icon" style="fill: #fff;">
                            <use xlink:href="images/svg-symbols.svg#plus-icon"></use>
                          </svg>
                        </button>
                        <button
                          class="c-button c-button--secondary c-button--tiny"
                          v-on:click.prevent="onUpdateSessionCounterProp(session.id, 'rotations', false)">
                          <svg role="img" class="c-button__icon" style="fill: #fff;">
                            <use xlink:href="images/svg-symbols.svg#minus-icon"></use>
                          </svg>
                        </button>
                      </div>
                    </div>
                    <template v-if="!session.mixed">
                      <div class="c-planning__session-set" v-for="set in session.setData"
                           v-bind:key="set.id">
                        <collapsible open :title="`Set ${set.id}`" :id="`section-set-${ set.id }`" ref="setWrapper">
                          <template v-slot:header></template>
                          <template v-slot:content>
                            <div class="c-planning__session-set-meta o-layout">
                              <div class="o-layout__item u-3-of-8">categories:</div>
                              <div class="o-layout__item u-2-of-8">{{ set.categories.length }}</div>
                            </div>
                            <div class="c-planning__session-set-meta o-layout">
                              <div class="o-layout__item u-3-of-8">participations:</div>
                              <div class="o-layout__item u-2-of-8">{{ set.totalParticipations }}</div>
                            </div>
                            <div class="c-planning__session-set-meta o-layout u-margin-bottom-small">
                              <div class="o-layout__item u-3-of-8">rotations:</div>
                              <div class="o-layout__item u-2-of-8 o-flex--justify-start">{{ set.rotations }} ({{ set.rotations
                                }} x {{ Math.ceil(set.totalParticipations / set.rotations) }})
                              </div>
                              <div class="o-layout__item u-3-of-8">
                                <button class="c-button c-button--primary c-button--tiny"
                                        v-on:click.prevent="onUpdateSetRotations(session.id, set, true)">
                                  <svg role="img" class="c-button__icon" style="fill: #fff;">
                                    <use xlink:href="images/svg-symbols.svg#plus-icon"></use>
                                  </svg>
                                </button>
                                <button class="c-button c-button--secondary c-button--tiny"
                                        v-on:click.prevent="onUpdateSetRotations(session.id, set, false)">
                                  <svg role="img" class="c-button__icon" style="fill: #fff;">
                                    <use xlink:href="images/svg-symbols.svg#minus-icon"></use>
                                  </svg>
                                </button>
                              </div>
                            </div>
                            <vddl-list :list="set.categories" :horizontal="false" :allowed-types="['freeCategory']"
                                       :drop="e => handleDropCategory(e, session, set.id)"
                                       :class="['c-planning__session-set-body c-list__body', { 'c-session__list has-no-child': set.categories.length === 0}]">
                              <vddl-draggable v-for="(category, index) in set.categories"
                                              class="o-layout c-planning__session-set-body-row c-list__row" :key="category.id"
                                              type="category-permanent" :draggable="category" :index="index"
                                              :wrapper="set.categories" :disable-if="true" effect-allowed="move">
                                <div class="c-planning__session-set-body-col u-3-of-12">{{ category.info.name }}</div>
                                <div class="c-planning__session-set-body-col u-2-of-12"><small>participations:</small> <span>{{ Math.round(getSessionCategoryParticipationsLength(category))}}</span>
                                </div>
                                <div class="c-planning__session-set-body-col u-2-of-12"><small>subdivision:</small> <span>{{ category.subDivision }}</span>
                                </div>
                                <div class="c-planning__session-set-body-col u-4-of-12">
                                  <!--<label for="region" class="u-hidden-visually">{{$t('regions')}}</label>-->
                                  <multi-select v-if=" category.info.rounds[0].subDivisions > 1 && config.regions.length > 0"
                                                v-model="category.regions" :options="config.regions" class="c-input--multiselect"
                                                :multiple="true" :searchable="false" label="name" track-by="id" :taggable="true"
                                                :hideSelected="true" :placeholder="$t('select.regions')" :closeOnSelect="false"
                                                :selectLabel="$t('select.selectOnEnter')"
                                                @select="selectRegion($event, session.id, set.id, category.info.id)"
                                                @remove="removeRegion($event, session.id, set.id, category.info.id)"
                                  />
                                </div>
                                <div class="c-planning__session-set-body-col u-1-of-12" >
                                  <button class="c-button c-button--ghost c-button--small c-button--icon"
                                          v-on:click.prevent="onRemoveCategoriesFromSession(session.id, [category])">
                                    <span class="u-hidden-visually">{{ $t("delete") }}</span>
                                    <svg role="img" class="c-button__icon">
                                      <use xlink:href="images/svg-symbols.svg#close"></use>
                                    </svg>
                                  </button>
                                </div>
                              </vddl-draggable>
                              <!--<vddl-placeholder class="c-session__list-placeholder">{{ $t("drop.placeholder") }}
                              </vddl-placeholder>-->
                            </vddl-list>
                          </template>
                        </collapsible>
                      </div>
                    </template>

                    <!--              mixed = table -->
                    <div v-if="session.mixed" class="o-layout">
                      <div class="o-layout__item">
                        <div class="c-planning__mixed-auto-scroll" >
                          <div class="c-planning__session-set-body-row"
                               :style="'width: ' + getMixedCategoriesSize(session.exerciseTypesUsed) + 'px'">
                            <div class="c-planning__session-set-body-col" :style="'width: ' + $options.mixedColumnSizes.category + 'px'">
                              {{ $t("category") }}
                            </div>
                            <div class="c-planning__session-set-body-col" :style="'width: ' + $options.mixedColumnSizes.exerciseType + 'px'">
                              {{ $t('participations') }}
                            </div>
                            <div class="c-planning__session-set-body-col" :style="'width: ' + $options.mixedColumnSizes.exerciseType + 'px'">
                              {{ $t("subDivision") }}
                            </div>
                            <div class="c-planning__session-set-body-col" :style="'width: ' + $options.mixedColumnSizes.exerciseType + 'px'"
                                v-for="exerciseType in session.exerciseTypesUsed" :key="exerciseType.id">
                              {{ $t("exercise.type." + exerciseType.id) }}
                            </div>
                            <div class="c-planning__session-set-body-col"
                                 :style="'width: ' + $options.mixedColumnSizes.regions + 'px'">
                              {{ $t('regions') }}
                            </div>
                            <div class="c-planning__session-set-body-col" :style="'width: ' + $options.mixedColumnSizes.actions + 'px'">&nbsp;</div>
                          </div>

                          <vddl-list :list="session.sessionCategories" :horizontal="false"
                                     :allowed-types="['freeCategory']" :drop="e => handleDropCategory(e, session, 1)"
                                     :class="['c-planning__session-set-body c-list__body', {'c-session__list has-no-child': session.sessionCategories.length === 0}]">
                            <vddl-draggable v-for="(sc, index) in session.sessionCategories" :key="sc.id"
                                            class="c-planning__session-set-body-row"
                                            :style="'width: ' + getMixedCategoriesSize(session.exerciseTypesUsed) + 'px'"
                                            type="category-permanent" :draggable="sc" :index="index"
                                            :wrapper="session.sessionCategories" :disable-if="true"
                                            effect-allowed="move">
                              <div class="c-planning__session-set-body-col" :style="'width: ' + $options.mixedColumnSizes.category + 'px'">
                                {{ sc.category.name }}
                              </div>
                              <div class="c-planning__session-set-body-col" :style="'width: ' + $options.mixedColumnSizes.exerciseType + 'px'">
                                {{Math.round(getSessionCategoryParticipationsLength(sc.sessionCategory))}}
                              </div>
                              <div class="c-planning__session-set-body-col" :style="'width: ' + $options.mixedColumnSizes.exerciseType + 'px'">
                                {{ sc.subdivision }}
                              </div>
                              <div class="c-planning__session-set-body-col" v-for="exerciseType in session.exerciseTypesUsed"
                                  :key="exerciseType.id" :style="'width: ' + $options.mixedColumnSizes.exerciseType + 'px'">
                                <div class="c-input"
                                     v-if="sc.exercises[exerciseType.id] !== undefined">
                                  <select class="c-input__field c-input__field-select"
                                          v-model="sc.exercises[exerciseType.id]"
                                          @change="e => onUpdatePanel(session.id, sc, exerciseType.id, e.target.value)"
                                          :name="'panel-' + exerciseType.id + '-' + sc.index">
                                    <option :value="0">{{ $t("session.doNotInclude") }}</option>
                                    <option v-for="x in getSets(session)" :value="x" :key="x">
                                      {{ $t("panel") }} {{ x }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div class="c-planning__session-set-body-col"
                                   :style="'width: ' + $options.mixedColumnSizes.regions + 'px'">
                                <multi-select v-if=" sc.subDivisions > 1 && config.regions.length"
                                              v-model="sc.regions" :options="config.regions" class="c-input--multiselect"
                                              :multiple="true" :searchable="false" label="name" track-by="id" :taggable="true"
                                              :hideSelected="true" :placeholder="$t('select.regions')" :closeOnSelect="false"
                                              :selectLabel="$t('select.selectOnEnter')"
                                              @select="selectRegion($event, session.id, 1, sc.category.id)"
                                              @remove="removeRegion($event, session.id, 1, sc.category.id)"
                                />
                              </div>
                              <div class="c-planning__session-set-body-col" :style="'width: ' + $options.mixedColumnSizes.actions + 'px'">
                                <button class="c-button c-button--ghost c-button--tiny c-button--icon"
                                        v-on:click.prevent="onRemoveCategoriesFromSession(session.id, [sc.sessionCategory])">
                                  <svg role="img" class="c-button__icon">
                                    <use xlink:href="images/svg-symbols.svg#close"></use>
                                  </svg>
                                </button>
                              </div>
                            </vddl-draggable>
                            <div v-if="! session.sessionCategories.length"
                                 :style="'height: 64px; width: ' + getMixedCategoriesSize(session.exerciseTypesUsed) + 'px'" />
                          </vddl-list>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </collapsible>
            </div>
          </div>
        </div>
      </div>

      <div class="o-layout__item c-planning__item-sidebar">
        <div class="c-planning__item-sidebar-sticky">
          <h3 class="c-title c-title--page-section">{{ $t("categories") }}</h3>
          <div class="c-list__body c-list__body-shadow">
          <vddl-list :list="categories" :horizontal="false" :allowed-types="[]" class="c-session__list">
            <vddl-draggable v-for="(category, index) in getFullCategories()"
                            :class="['c-list__row c-list__row--draggable u-padding-right-small u-padding-left-medium u-padding-vertical-medium u-margin-bottom-small', getCategoriesPlanned(category) === category.rounds[0].subDivisions && 'c-list__row--all-gone', getCategoriesPlanned(category) === 0 && 'c-list__row--none-gone', (getCategoriesPlanned(category) !== category.rounds[0].subDivisions) && (getCategoriesPlanned(category) !== 0) && 'c-list__row--some-gone']"
                            :key="category.id" type="freeCategory" :draggable="category" :index="index"
                            :wrapper="categories"
                            :disable-if="getCategoriesPlanned(category) >= category.rounds[0].subDivisions"
                            effect-allowed="copy"
                            :data-planned="getCategoriesPlanned(category) === category.rounds[0].subDivisions ? 'all planned' : getCategoriesPlanned(category) === 0 ? 'none planned' : 'some planned'">
              <div class="o-layout o-layout--flush ">
                <div class="o-layout__item o-flex o-flex--align-center u-margin-bottom-medium">
                  <h2 class="u-h6 u-margin-bottom-none">{{ category.name }}</h2>
                </div>

                <div class="o-layout__item u-2-of-3 o-flex o-flex--align-end">
                  <span>Sub Divisions:</span>
                </div>
                <div class="o-layout__item u-1-of-3 o-flex o-flex--align-center o-flex--justify-center">
                  <button class="c-button c-button--primary c-button--tiny"
                          v-on:click.prevent="onUpdateCategoryDivisions(category.id, true)">
                    <svg role="img" class="c-button__icon" style="fill: #fff;">
                      <use xlink:href="images/svg-symbols.svg#plus-icon"></use>
                    </svg>
                  </button>
                  <button class="c-button c-button--secondary c-button--tiny"
                          v-on:click.prevent="onUpdateCategoryDivisions(category.id, false)">
                    <svg role="img" class="c-button__icon" style="fill: #fff;">
                      <use xlink:href="images/svg-symbols.svg#minus-icon"></use>
                    </svg>
                  </button>
                </div>

                <div class="o-layout__item u-2-of-3">
                  <small>Total:</small>
                </div>
                <div class="o-layout__item u-1-of-3 o-flex o-flex--justify-center">
                  <span>{{ category.rounds[0].subDivisions }}</span>
                </div>

                <div class="o-layout__item u-2-of-3">
                  <small>Scheduled:</small>
                </div>
                <div class="o-layout__item u-1-of-3 o-flex o-flex--justify-center">
                  <span>{{ getCategoriesPlanned(category) }}</span>
                </div>


                <div class="o-layout__item u-2-of-3 u-margin-top-small u-margin-bottom-tiny">
                  <span>Participations:</span>
                </div>
                <div class="o-layout__item u-1-of-3 o-flex o-flex--justify-center u-margin-top-small u-margin-bottom-tiny">
                  <span>{{ category.participations.length }}</span>
                </div>
                <div class="o-layout__item">
                  <div class="o-layout o-layout--flush"
                       v-for="region in getParticipationsOnRegion(category.participations)" v-bind:key="region.id">
                    <div class="o-layout__item u-2-of-3 o-flex o-flex--justify-end">
                      <small>{{ region.name }}</small>
                    </div>
                    <div class="o-layout__item u-1-of-3 o-flex o-flex--justify-center">
                      <small>{{ region.count }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </vddl-draggable>
          </vddl-list>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import planning from 'client/lib/planning';
  import sessionLib from 'client/lib/session';

  const moment = require("moment");

  import find from 'lodash/find';
  import filter from 'lodash/filter';
  import findIndex from 'lodash/findIndex';
  import forEach from 'lodash/forEach';
  import forOwn from 'lodash/forOwn';
  import includes from 'lodash/includes';
  import map from 'lodash/map';
  import range from 'lodash/range';
  import reduce from 'lodash/reduce';
  import sortBy from 'lodash/sortBy';

  import vueMultiSelect from 'vue-multiselect';
  import 'vue-multiselect/dist/vue-multiselect.min.css';

  export default {
    name: "planning",
    props: ['event'],
    components: {
      collapsible: require("client/components/collapsible.vue").default,
      'multi-select': vueMultiSelect,
    },
    mixedColumnSizes: {
      category: 160,
      exerciseType: 125,
      regions: 175,
      actions: 40,
      base: 645,
    },
    data: function() {
      return {
        sessionsWrapper: null,
        setsWrapper: null,
        export: {},
        sessionDates: [],
      };
    },
    mounted() {
      this.sessionsWrapper = this.$refs.sessionWrapper;
      this.setsWrapper = this.$refs.setWrapper;
    },
    computed: {
      eventDiscipline: function () {
        return find(
          this.$store.state.eventDisciplines.items,
          item => item.id === this.$route.params.disciplineId
        );
      },
      country: function () {
        return find(
          this.$store.state.events.countries,
          c => c.id === this.event.countryId
        );
      },
      config: function () {
        const rs = this.eventDiscipline.regions || [];
        return {
          regions: map(rs, rs => find(this.country.regions, r => r.id === rs))
        };
      },
      sessions: function () {
        return sortBy(this.$store.state.sessions.items, 'index');
      },
      blocks: function () {
        return this.$store.state.blocks.items;
      },
      rounds: function () {
        return this.$store.state.eventDiscipline.rounds;
      },
      discipline: function () {
        return this.$store.state.eventDiscipline.discipline;
      },
      categories: function () {
        return this.$store.state.categories.items;
      },
      exerciseTypes: function () {
        return this.$store.state.eventDiscipline.exerciseTypes;
      },
      formats: function () {
        return this.$store.state.eventDiscipline.formats;
      },
      participations: function () {
        return filter(
          this.$store.state.participations.items,
          i => i.participantType !== "team"
        );
      },
      blockParticipations: function () {
        return this.$store.state.blockParticipations.items;
      },
      members: function () {
        return this.$store.state.members.items;
      },
      teams: function () {
        return this.$store.state.teams.items;
      },
      clubs: function () {
        return this.$store.state.clubs.items;
      },
      mixed: function () {
        return this.discipline.rotationType === "mixed";
      }
    },
    watch: {
      sessions: {
        immediate: true,
        handler: 'initSessionData'
      }
    },
    methods: {
      initSessionData: function() {
        const days = planning.getEventDays(this.event);
        this.sessionDates = map(days, (day) => {
          const sessions = this.getSessionsByDate(day);

          return {
            date: day,
            sessions: sessions,
          };
        });
      },

      getSessionsByDate: function (date) {
        return filter(
          this.getFullSessions(),
          s => new Date(s.date).getTime() === date.getTime()
        );
      },

      getFullSessions: function () {
        const fSessions = [];
        forEach(this.sessions, session => {
          let totalParticipations = 0;
          forEach(session.categories, sCategory => {
            if (sCategory.set <= session.sets) {
              const categoryCount = planning.getSessionCategoryParticipationsLength(
                sCategory
              );
              totalParticipations += categoryCount;
            }
          });

          const rotationType = sessionLib.getSessionRotationType(session.id)

          const sessionData = {
            ...session,
            totalParticipations: Math.round(totalParticipations),
            mixed: rotationType === 'mixed',
          }

          if (rotationType === 'mixed') {
            sessionData.exerciseTypesUsed = this.getExerciseTypesUsed(session)
            sessionData.sessionCategories = this.getCurrentSessionCategories(session)
            // sessionData.participationLength = this.getSessionCategoryParticipationsLength()
          } else {
            sessionData.setData = planning.getSessionSets(session, this.config, sessionData)
          }

          fSessions.push(sessionData);
        });
        return fSessions;
      },

      getMixedCategoriesSize: function(exerciseTypes = null) {
        let size = this.$options.mixedColumnSizes.base

        if (exerciseTypes) {
          size += exerciseTypes.length * this.$options.mixedColumnSizes.exerciseType
        }

        /*if (this.config.regions?.length) {
          size += this.$options.mixedColumnSizes.exerciseType
        }*/

        return size
      },

      prepareBlock: function(block) {
        const parts = block.participations.map(part => ({
          part,
        }))

        return {
          block: block.block,
          parts
        }
      },
      prepareMixedBlock: function(block) {
        const parts = block.exercises.map(exercise => ({
          part: exercise.part,
          exerciseTypeId: exercise.exerciseTypeId,
        }))

        return {
          block: block.block,
          parts
        }
      },

      getDateStr: function (date) {
        return moment(date).format("dddd DD-MM-YYYY");
      },

      sessionTitle: function (session) {
        let title = ''
        if (this.rounds.length > 1) {
          const round = this.rounds.find(r => r.id === session.roundId)
          title += round.name + ' '
        }
        title += session.name
        return title
      },

      collapseAll: function () {
        this.setsWrapper && this.collapseElementsIn(this.setsWrapper);
        this.sessionsWrapper && this.collapseElementsIn(this.sessionsWrapper);
      },
      collapseElementsIn: function (arrayElements) {
        arrayElements.forEach(el => el.collapse());
      },
      expandAll: function () {
        this.setsWrapper && this.expandElementsIn(this.setsWrapper);
        this.sessionsWrapper && setTimeout(this.expandElementsIn.bind(null, this.sessionsWrapper), 250);
      },
      expandElementsIn: function (arrayElements) {
        arrayElements.forEach(el => el.expand());
      },

      onUpdateSetRotations: function (sessionId, set, add) {
        // Rotations cannot be lower than 1
        if (!add && set.rotations === 1) return;

        const session = this.getSessionClone(sessionId);

        // calc new rotation
        const newRotation = add ? set.rotations + 1 : set.rotations - 1;

        // Get previous setProperties or create new one
        let setProps =
          session.setProperties &&
          find(session.setProperties, sp => sp.set === set.id);
        if (!setProps) {
          session.setProperties = session.setProperties || [];
          setProps = {
            set: set.id,
            rotations: newRotation,
            id: String(set.id - 1)
          };
          session.setProperties.push(setProps);
        }

        // set new rotation on Set
        setProps.rotations = newRotation;

        // Update rotation on session level
        let maxSetProp = reduce(
          session.setProperties,
          (max, prop) => (prop.rotations >= max.rotations ? prop : max),
          {rotations: 1}
        );
        if (session.setProperties.length === session.sets) {
          session.rotations = maxSetProp.rotations;
        } else {
          session.rotations =
            session.rotations >= maxSetProp.rotations
              ? session.rotations
              : maxSetProp.rotations;
        }

        // Save session
        this.updateSession(session);
      },

      getSessionCategoryParticipationsLength: function (sessionCategory) {
        return planning.getSessionCategoryParticipationsLength(sessionCategory);
      },

      getFullCategories: function () {
        const fCategories = [];
        forEach(this.categories, category => {
          const participations = filter(
            this.participations,
            i => i.categoryId === category.id
          );
          fCategories.push({...category, participations});
        });
        return sortBy(fCategories, "index");
      },

      getParticipationsOnRegion: function (participations) {
        let regions = [];
        let counterOthers = 0;
        forEach(participations, p => {
          const member = this.getMemberByParticipationId(p.participantId);
          if (member) {
            const club = find(this.clubs, club => club.id === member.clubId);
            const regionInConfig = find(
              this.config.regions,
              r => r.id === club.regionId
            );
            if (regionInConfig) {
              const clubRegion = find(regions, r => r.id === club.regionId);
              clubRegion
                ? clubRegion.count++
                : regions.push({
                  id: club.regionId,
                  count: 1,
                  name: regionInConfig.name
                });
            } else {
              counterOthers++;
            }
          }
        });
        if (counterOthers) {
          if (counterOthers === participations.length) {
            regions = [];
          } else {
            regions.push({id: "others", count: counterOthers, name: "Others"});
          }
        }
        return regions;
      },

      getMemberByParticipationId: function (participantId) {
        return find(this.members, member => member.id === participantId);
      },

      onUpdateSessionCounterProp: function (sessionId, prop, add) {
        const session = this.getSessionClone(sessionId);
        if (session[prop] === 0 && !add) return;
        add ? session[prop]++ : session[prop]--;
        this.updateSession(session);
      },

      onAddSessionOnDate: function (date, dateIndex) {
        const planningConfig = this.eventDiscipline.planningConfig;
        const sessions = this.getSessionsByDate(date);
        // const daySessions = sessions[dateIndex];
        let sets = 1;
        let rotations = 1;
        let rotationType = 'fixed';

        if (planningConfig) {
          if (planningConfig.sets) {
            sets = planningConfig.sets;
          }
          if (planningConfig.rotations) {
            rotations = planningConfig.rotations;
          }
          rotationType = planningConfig.rotationType ? planningConfig.rotationType : 'fixed';
        }

        const setProperties = map(range(sets), s => {
          return {
            set: s+1,
            rotations: rotations,
            id: s,
          };
        });

        const session = {
          index: dateIndex * 100 + sessions.length,
          eventDisciplineId: this.$route.params.disciplineId,
          name: this.$t('session') + ' ' + (sessions.length + 1),
          roundId: this.rounds[0].id,
          date: moment(date).format('YYYY-MM-DD'),
          description: undefined,
          sets: sets,
          rotations: rotations,
          rotationType: rotationType,
          rotationFree: false,
          switchPanels: false,
          schedule: {
            judgemeeting: undefined,
            marchin: undefined,
            competition: undefined,
            ceremony: undefined
          },
          sessionCategories: [],
          newCategory: 0,
          exerciseTypesUsed: [],
          country: undefined,
          rankingTypes: [],
          exerciseTypesSelected: [],
          setLabel: this.mixed ? 'panel' : 'set',
          setProperties: setProperties,
        };

        this.$notify.saveAsync(
          this.$t('session'),
          this.$store.dispatch('session.add', session),
        );
      },

      onRemoveCategoriesFromSession: function (sessionId, categories) {
        const session = this.getSessionClone(sessionId);
        let sessionCategories = this.getCurrentSessionCategories(session);
        forEach(categories, category => {
          sessionCategories = filter(sessionCategories, function (item) {
            return (
              item.category.id !== category.categoryId ||
              item.set !== category.set ||
              item.subdivision !== category.subDivision
            );
          });
          session.categories = this.parseSessionCategories(sessionCategories);
        });
        this.updateSession(session);
      },

      updateSession: function (session) {
        this.$notify.saveAsync(
          this.$t("session"),
          this.$store.dispatch("session.save", session)
        );
      },

      onRemoveSession: function (session) {
        this.$modal.show({
          title: this.$t('delete.confirm.title'),
          message: this.$t('delete.confirm.text', {item: session.name}),
          onConfirm: () => {
            this.$notify.removeAsync(
              this.$t("session"),
              this.$store.dispatch("session.remove", session),
              () => {
                this.$navigation.goto({name: "admin.event.discipline.sessions"});
              }
            );
          }
        });
      },

      getSessionClone: function (sessionId) {
        const s = find(this.sessions, s => s.id === sessionId);
        const clonedSession = JSON.parse(JSON.stringify(s));
        clonedSession._rev = undefined;
        return clonedSession;
      },

      handleDropCategory: function (e, session, set) {
        const category = e.item;
        const hasSessionCategory = find(
          session.categories,
          sessionCategory =>
            sessionCategory.categoryId === category.id &&
            sessionCategory.set === set
        );
        if (!hasSessionCategory) {
          const allSessions = this.getFullSessions();
          const sessionCategories = [];
          forEach(allSessions, s => {
            sessionCategories.push(
              ...filter(
                s.categories,
                sessionCategory => sessionCategory.categoryId === category.id
              )
            );
          });

          let newSubDivision;
          find(Array(category.rounds[0].subDivisions).fill(), (v, i) => {
            const index = findIndex(
              sessionCategories,
              sc => sc.subDivision === i + 1
            );
            if (index === -1) {
              newSubDivision = i + 1;
              return true;
            }
          });
          if (newSubDivision !== undefined) {
            this.addCategoryOnSession(session, set, category, newSubDivision);
          }
        }
      },

      addCategoryOnSession: function (session, set, category, subdivision) {
        let message = null;
        const updates = planning.updateWarmups(session, category);
        console.log('updates', updates);
        if (updates.length) {
          message = this.$t('session.updateWarmups.message');
          forEach(updates, u => {
            message += ' ' + u.name + ': ' + u.oldWarmup + ' -> ' + u.newWarmup + ',';
          });
        }

        this.confirmCategoryUpdate(message, result => {
          if (! result) return;

          const clonedSession = this.getSessionClone(session.id);

          if (updates.length) {
            const s = find(updates, u => u.id === session.id);
            if (s) clonedSession.warmup = s.newWarmup;

            forEach(updates, u => {
              if (u.id !== session.id) {
                this.updateSession({
                  id: u.id,
                  warmup: u.newWarmup,
                });
              }
            });
          }

          const sessionCategories = this.getCurrentSessionCategories(clonedSession);
          const format = this.getCategoryFormat(category);
          const exercises = {};
          forEach(format.exercises, function (item) {
            exercises[item.exerciseTypeId] = 1;
          });
          sessionCategories.push({
            category: category,
            set,
            subdivision,
            exercises: exercises,
          });
          clonedSession.categories = this.parseSessionCategories(sessionCategories);
          this.updateSession(clonedSession);
        });
      },

      confirmCategoryUpdate: function(message, cb) {
        if (! message) {
          console.log('pass without confirmation');
          return cb(true);
        }

        this.$modal.show({
          title: this.$t('session.updateWarmups'),
          message: message,
          onConfirm: () => {
            cb(true);
          },
          onCancel: () => {
            cb(false);
          },
        });
      },

      parseSessionCategories: function (sessionCategories) {
        const categories = [];
        forEach(sessionCategories, (sessionCategory, index) => {
          let category = {
            categoryId: sessionCategory.category.id,
            set: sessionCategory.set,
            subDivision: sessionCategory.subdivision,
            exercises: [],
            id: index + 1
          };

          let id2 = 0;
          forOwn(sessionCategory.exercises, (val, key) => {
            category.exercises.push({
              exerciseTypeId: key,
              set: val,
              id: index + 1 + "-" + id2++
            });
          });
          categories.push(category);
        });
        return categories;
      },

      getCurrentSessionCategories: function (session) {
        const sessionCategories = [];
        forEach(session.categories, sessionCategory => {
          const category = find(
            this.categories,
            item => item.id === sessionCategory.categoryId
          );
          const exercises = {};
          forEach(sessionCategory.exercises, categoryExercise => {
            exercises[categoryExercise.exerciseTypeId] = categoryExercise.set;
          });
          sessionCategories.push({
            id: sessionCategory.id,
            category: category,
            subDivisions: category.rounds[0].subDivisions,
            set: sessionCategory.set,
            subdivision: sessionCategory.subDivision,
            regions: map(sessionCategory.regions, r => find(this.config.regions, r2 => r2.id === r)),
            exercises: exercises,
            sessionCategory
          });
        });
        return sessionCategories;
      },

      getCategoryClone: function (categoryId) {
        const category = find(this.categories, c => c.id === categoryId);
        const clonedCategory = JSON.parse(JSON.stringify(category));
        clonedCategory._rev = undefined;
        return clonedCategory;
      },

      updateCategory: function (category) {
        this.$notify.saveAsync(
          this.$t("category"),
          this.$store.dispatch("category.save", category)
        );
      },

      getCategoryFormat: function (category) {
        const categoryRound = category.rounds[0];
        return find(this.formats, function (item) {
          return item.id === categoryRound.formatId;
        });
      },

      onUpdateCategoryDivisions: function (categoryId, add) {
        const category = this.getCategoryClone(categoryId);
        if (category.rounds[0].subDivisions === 1 && !add) return;
        add
          ? category.rounds[0].subDivisions++
          : category.rounds[0].subDivisions--;
        this.updateCategory(category);
      },

      selectRegion: function(event, sessionId, set, categoryId) {
        console.log('region select', event, sessionId, categoryId, set);
        const session = this.getSessionClone(sessionId);
        const category = find(
          session.categories,
          c => c.categoryId === categoryId && c.set === set
        );
        if (! category.regions) {
          category.regions = [];
        }
        category.regions.push(event.id);
        this.updateSession(session);
      },

      removeRegion: function(event, sessionId, set, categoryId) {
        console.log('region remove', event.id);
        const session = this.getSessionClone(sessionId);
        forEach(
            session.categories,
            c => {
              if (c.categoryId === categoryId && c.set === set) {
                c.regions = filter(c.regions, r => r !== event.id);
              }
            }
        );
        this.updateSession(session);
      },

      onUpdateRegion: function (e, sessionId, set, categoryId) {
        console.log(e);
        const newValue = e.target.value;
        const session = this.getSessionClone(sessionId);
        const category = find(
          session.categories,
          c => c.categoryId === categoryId && c.set === set
        );
        category.regionId = newValue || undefined;
        this.updateSession(session);
      },

      getCategoriesPlanned: function (category) {
        return reduce(
          this.sessions,
          (accum, session) => {
            forEach(session.categories, cat => {
              cat.categoryId === category.id && accum++;
            });
            return accum;
          },
          0
        );
      },

      getExerciseTypesUsed: function (session) {
        let exerciseTypeIds = [];
        const sessionCategories = this.getCurrentSessionCategories(session);
        forEach(sessionCategories, function (item) {
          forOwn(item.exercises, function (val, key) {
            exerciseTypeIds.push(key);
          });
        });
        return sortBy(
          filter(this.exerciseTypes, function (item) {
            return includes(exerciseTypeIds, item.id);
          }),
          "index"
        );
      },

      getSets: function (session) {
        return range(1, parseInt(session.sets) + 1);
      },

      onUpdatePanel: function (sessionId, sessionCategory, exerciseId, panel) {
        const session = this.getSessionClone(sessionId);
        const sessionCategories = this.getCurrentSessionCategories(session);
        const originSessionCategory = find(
          sessionCategories,
          sc => sc.id === sessionCategory.id
        );
        originSessionCategory.exercises[exerciseId] = panel;
        session.categories = this.parseSessionCategories(sessionCategories);
        this.updateSession(session);
      },

      getExercisePanels: function (session) {
        const panels = this.getSets(session);
        const dataPanels = [];
        forEach(panels, p => {
          let panelParticipations = 0;
          forEach(session.categories, sc => {
            const exercises = filter(sc.exercises, e => e.set === p);
            const participations = this.getSessionCategoryParticipationsLength(sc)
            panelParticipations += Math.round(participations * exercises.length)
          });
          dataPanels.push({id: p, participations: panelParticipations});
        });
        return dataPanels;
      },

      getTotalExercisePanels: function (session) {
        const panels = this.getExercisePanels(session);
        return reduce(panels, (accum, p) => accum + p.participations, 0);
      },
    }
  };
</script>

