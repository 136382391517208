<template>
  <div class="c-main">
    <form action="#" class="c-main--flex-1 u-margin-bottom-large">
      <sceTextField v-model="title" name="title" label="title"
                    v-validate data-vv-rules="required" :class="{'is-invalid': errors.has('title')}"/>

      <div class="o-layout">
        <div class="o-layout__item u-1-of-2-at-small">
          <sceTextField v-model="titleShort" name="titleShort" label="event.title_short"/>
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceTextField v-model="venue" name="venue" label="venue"/>
        </div>
      </div>

      <div class="o-layout">
        <div class="o-layout__item u-1-of-2-at-small">
          <sceSelect v-model="countryId" name="country" label="country"
                     :options="countries" :config="{optionLabel: 'id', emptyLabel: 'none'}"/>
        </div>
        <div v-if="countryId && regions.length" class="o-layout__item u-1-of-2-at-small">
          <sceSelect v-model="regionId" name="region" label="region" :options="regions"
                     :config="{localized: false, emptyLabel: 'none'}"/>
        </div>

        <div class="o-layout__item u-1-of-2-at-small">
          <sceSelect v-model="language" name="language" label="language" :options="$options.languages"/>
        </div>
      </div>

      <div class="o-layout">
        <div class="o-layout__item u-3-of-4-at-small u-1-of-2-at-large">
          <div class="c-input p-date-picker">
            <label class="c-input__label" for="dates">{{ $t('date') }}</label>
            <HotelDatePicker format="DD - MMM - YYYY" id="dates" name="dates" :startingDateValue="fromDate"
                             :endingDateValue="toDate" :startDate="new Date(1990,1,1)" :endDate="dateEndDate()"
                             :showYear="true" :i18n="$options.labelsDatePicker" :minNights="0" :firstDayOfWeek="1"
                             @check-in-changed="updateFromDate($event)" @check-out-changed="updateToDate($event)"
            />
          </div>
        </div>
        <div class="o-layout__item u-1-of-2-at-small">
          <sceTextField v-model="copyright" name="copyright" label="copyright"/>
        </div>
      </div>

      <div class="o-layout">
        <label class="c-input__label o-layout__item">{{ $t('event.logo') }}</label>
      </div>
      <image-input v-model="logoUrl"/>
    </form>
    <footer class="c-footer c-footer--bottom-sticky c-footer--bottom-sticky-fixed-height o-layout">
      <div class="o-layout__item u-1-of-2-at-tiny">
        <sceButton v-if="id && ! isLocal" class="c-button--secondary c-button--large" v-on:click="remove()" label="delete"/>
      </div>

      <div class="o-layout__item u-1-of-2-at-tiny u-text-right">
        <sceButton class="c-button--primary c-button--large" v-on:click="submit()" label="save"/>
      </div>
    </footer>
  </div>
</template>

<script type="text/babel">
import form from 'client/lib/form';
import lang from 'client/lang';

import HotelDatePicker from "vue-hotel-datepicker";

import find from 'lodash/find';

const moment = require('moment');

export default {
  name: "event",
  components: {
    'image-input': require('client/components/imageInput.vue').default,
    HotelDatePicker: HotelDatePicker,
  },
  props: ['id'],
  languages: form.idNamePairs(require('/locales/index'), 'languages.'),
  data: function () {
    return {
      title: '',
      titleShort: '',
      venue: '',
      copyright: null,
      fromDate: new Date(),
      toDate: new Date(),
      language: '',
      logoUrl: '',
      countryId: undefined,
      regionId: undefined,
      status: 'scheduled',
      regions: []
    }
  },
  computed: {
    event: function () {
      return this.$store.state.events.current
    },
    countries: function () {
      return this.$store.state.events.countries;
    },
    user: function () {
      return this.$store.state.user.instance;
    },
    isLocal: function() {
      return this.$store.state.environment.local
    },
  },
  created: function () {
    this.$options.labelsDatePicker = {
      night: this.$t('day'),
      nights: this.$t('days'),
      'day-names': lang.getDays(),
      'check-in': this.$t('date.start').trim(),
      'check-out': this.$t('date.end').trim(),
      'month-names': lang.getMonths(),
    };

    this.setEvent();
  },
  watch: {
    event: function () {
      this.setEvent();
    },
    countryId: function () {
      this.setRegions();
    },
  },
  methods: {
    dateEndDate: function () {
      return moment().add(3, 'years').toDate();
    },
    updateFromDate: function (newDate) {
      console.log('new fromDate', newDate);
      this.fromDate = newDate;
    },
    updateToDate: function (newDate) {
      console.log('new toDate', newDate);
      this.toDate = newDate;
    },
    setEvent: function () {
      if (this.event) {
        this.fromDate = this.event.date_from ? new Date(this.event.date_from.substring(0, 10)) : new Date();
        this.toDate = this.event.date_to ? new Date(this.event.date_to.substring(0, 10)) : new Date();
        this.title = this.event.title;
        this.titleShort = this.event.titleShort;
        this.copyright = this.event.copyright;
        this.venue = this.event.venue;
        this.language = this.event.language;
        this.logoUrl = this.event.logoUrl;
        this.countryId = this.event.countryId;
        this.regionId = this.event.regionId;
        this.status = this.event.status ? this.event.status : 'scheduled';
      } else {
        // init event from user profile
        if (this.user.locale) {
          this.language = this.user.locale;
        }
        if (this.user.countryId) {
          this.countryId = this.user.countryId;
        }
        if (this.user.copyright) {
          this.copyright = this.user.copyright;
        }
        const user = this.$store.state.user.instance;
        if (user.logoUrl) {
          this.logoUrl = user.logoUrl;
        }
      }
    },
    setRegions: function () {
      const countryId = this.countryId;
      if (countryId) {
        const country = find(this.countries, item => item.id === countryId);
        if (country && country.regions) {
          this.regions = country.regions;
        } else {
          this.regions = [];
        }
      } else {
        this.regions = [];
      }

      if (this.regionId) {
        const region = find(this.regions, item => item.id === this.regionId);
        if (!region) {
          this.regionId = null;
        }
      }
    },
    submit: function () {
      this.$validator.validateAll().then(() => {
        if (!this.errors.any()) {
          this.$emit('submit', {
            date_from: moment(this.fromDate).format('YYYY-MM-DD'),
            date_to: moment(this.toDate).format('YYYY-MM-DD'),
            title: this.title,
            titleShort: this.titleShort,
            copyright: this.copyright,
            venue: this.venue,
            logoUrl: this.logoUrl,
            language: this.language,
            countryId: this.countryId,
            regionId: this.regionId,
            id: this.id,
            status: this.status,
          });
        } else {
          this.$notify.warning('Validation errors');
        }
      });
    },
    remove: function () {
      this.$modal.show({
        title: this.$t('delete.confirm.title'),
        message: this.$t('delete.confirm.text', {item: this.title}),
        onConfirm: () => {
          this.$emit('remove', this.id);
        }
      });
    }
  }
};
</script>

<style>
</style>
